<template>
  <div class="a-tabel">
    <!-- 可以拖拽改变表格列宽 -->
    <a-table
      v-if="dragable"
      :ref="tableRef"
      v-bind="{ ...$props }"
      :components="components"
      @change="handleTableChange"
    >
      <template
        v-for="slot in scopedSlots"
        :slot="slot"
        slot-scope="text, record, index"
      >
        <slot :name="slot" :scope="{ text, record, index }" />
      </template>
      <template v-for="slot in slots" :slot="slot">
        <slot :name="slot" />
      </template>
      <template
        :slot="$scopedSlots.expandedRowRender ? 'expandedRowRender' : ''"
        slot-scope="record, index, indent, expanded"
      >
        <slot
          v-bind="{ record, index, indent, expanded }"
          :name="$scopedSlots.expandedRowRender ? 'expandedRowRender' : ''"
        />
      </template>
    </a-table>
    <!-- 不可以拖拽改变表格列宽 -->
    <a-table
      v-else
      :ref="tableRef"
      v-bind="{ ...$props }"
      :data-source="subDataSource"
      @change="handleTableChange"
    >
      <template
        v-for="slot in scopedSlots"
        :slot="slot"
        slot-scope="text, record, index"
      >
        <template v-if="slot==='operation'">
          <slot :name="slot" :scope="getOperationVal(text, index)" />
        </template>
        <template v-else>
          <slot :name="slot" :scope="{ text, record, index }" />
        </template>
      </template>
      <template v-for="slot in slots" :slot="slot">
        <slot :name="slot" />
      </template>
      <template
        :slot="$scopedSlots.expandedRowRender ? 'expandedRowRender' : ''"
        slot-scope="record, index, indent, expanded"
      >
        <slot
          v-bind="{ record, index, indent, expanded }"
          :name="$scopedSlots.expandedRowRender ? 'expandedRowRender' : ''"
        />
      </template>
    </a-table>
  </div>
</template>

<script>
import drag from './drag.js'
export default {
  mixins: [drag],
  props: {
    tableRef: {
      // 表格所在页面的父级类名
      requires: false,
      default: '',
      type: String
    },
    isFooter: {
      // 表格底部 除分页外有其他元素
      requires: false,
      default: false,
      type: Boolean
    },
    columns: {
      // 表格的配置项
      requires: true,
      default: () => [],
      type: Array
    },
    dataSource: {
      // 数据项
      requires: true,
      default: () => [],
      type: Array
    },
    // 是否展示表格线框
    bordered: {
      type: Boolean,
      default: false
    },
    // 是否可以拖拽来改变表格列宽
    dragable: {
      type: Boolean,
      default: false
    },
    rowKey: {
      // 唯一值 可以是dataSource中的唯一值 如id
      requires: true,
      default: null,
      type: [String, Function]
    },
    loading: {
      // 表格是否loading
      default: false,
      type: Boolean
    },
    rowSelection: {
      requires: false,
      default: null,
      type: Object
    },
    pagination: {
      requires: false,
      type: [Object, Boolean],
      default: () => {}
    },
    scroll: {
      type: Object,
      default: () => {
        return { x: 1000, y: 600 }
      }
    },
    isReportForm: {
      require: false,
      type: Boolean,
      default: false
    },
    tabsHeight: {
      requires: false,
      type: Number,
      default: 0
    },
    offsetBottom: {
      requires: false,
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      subDataSource: [], // 处理完成的数据
      num: 0
    }
  },
  computed: {
    slots() {
      return Object.keys(this.$slots).filter((slot) => slot !== 'title')
    },
    scopedSlots() {
      return Object.keys(this.$scopedSlots).filter((slot) => slot !== 'title')
    },
    getOperationVal() {
      // 表格中插槽scope数据去掉千分位格式 record接口中源数据
      return (text, index) => {
        return {
          text,
          record: this.dataSource[index],
          index
        }
      }
    }

  },
  watch: {
    dataSource: {
      handler: function(value) {
        if (value) {
          this.$nextTick(() => {
            this.pagerBottom()
            // 数据处理
            this.formatValue(value)
          })
        }
        return false
      },
      immediate: true,
      deep: true
    },
    pagination: {
      immediate: true,
      deep: true,
      handler: function(value) {
        if (value) {
          this.$props.pagination.pageSizeOptions = ['10', '20', '50', '100']
        }
      }
    }
  },
  updated() {
    this.$nextTick(() => {
      this.pagerBottom()
    })
    if (this.$props.dataSource.length && !this.num) {
      this.$nextTick(() => {
        setTimeout(() => {
          const obj = this.$props.dataSource[0]
          this.$props.dataSource.splice(0, 1, obj)
          this.num++
        }, 200)
      })
    }
  },
  methods: {
    // 分页器置底
    pagerBottom() {
      const width = document.querySelector(`.a-tabel`).clientWidth
      const bottom = document.querySelector(`.ant-spin-container ul`)
      const tableTop = document.querySelector(`.a-tabel`).offsetTop
      const tableH = document.querySelector(`.a-tabel`).clientHeight
      const contentH = document.body.scrollHeight - 48
      const headerH = document.querySelector('.ant-table-thead').clientHeight
      if (this.pagination) {
        this.$set(this.$props.scroll, 'y', contentH - tableTop - headerH - 64 - this.offsetBottom - this.tabsHeight)
        if (bottom) {
          if (tableH - 64 < contentH - tableTop - this.offsetBottom - this.tabsHeight) {
            bottom.setAttribute('style',
              `width:${width}px;
             position: absolute;
             top: ${contentH - tableTop - 64 - this.offsetBottom - this.tabsHeight}px;
             background: #fff;
             padding: 16px 0;
             margin:0;
             z-index:1`)
          }
        }
      } else {
        if (this.isReportForm) {
          this.$props.scroll.y = contentH - headerH - tableTop - 290
        } else {
          this.$props.scroll.y = contentH - tableTop - headerH - this.offsetBottom - this.tabsHeight
        }
      }
    },
    handleTableChange(pagination, filters, sorter, options) {
      this.$emit('change', pagination, filters, sorter, options)
    },
    // 格式化数据
    formatValue(value) {
      // 防止浅拷贝
      // const value = JSON.parse(JSON.stringify(subValue))
      // 需要格式化的列
      const isColumns = []
      console.log('this.columns', this.columns)
      // this.columns.forEach(({ isMoney, dataIndex, isNumber }, index) => {
      //   if (isMoney) {
      //     isColumns.push(dataIndex)
      //     this.columns[index].align = 'right'
      //   }
      //   if (isNumber) {
      //     this.columns[index].align = 'right'
      //   }
      // })
      // 处理分组表头
      this.columns.forEach((item, index) => {
        if (item.children && item.children.length !== 0) {
          item.children.map((items, indexs) => {
            isColumns.push(items.key)
            this.columns[index].children[indexs].align = 'right'
          })
        }
      })
      const getIsFormat = (columns) => {
        if (columns.children && columns.children.length) {
          getIsFormat(columns.children)
        }
        columns.forEach(({ dataType, dataIndex }, index) => {
          if (dataType === 'money') {
            isColumns.push(dataIndex)
            this.columns[index].align = 'right'
          }
          if (dataType === 'number') {
            this.columns[index].align = 'right'
          }
        })
      }

      getIsFormat(this.columns)
      if (!isColumns.length) {
        this.subDataSource = value
        return
      }
      console.log('需要格式化的', isColumns)
      this.subDataSource = value.map((item) => {
        let fanlVal = item
        isColumns.forEach((key) => {
          fanlVal = {
            ...fanlVal,
            [key]: this.stateFormatNum(item[key])
          }
        })
        return fanlVal
      })
    },
    // 表格数据处理千分位
    stateFormatNum(value) {
      if (value === null || value === undefined) {
        return ''
      }
      // 处理组合表格字段不是money类型
      if (!value || !Number(value)) {
        if ((value + '').indexOf('-') !== -1 || (value + '').indexOf('-') !== '-1') {
          return value + ''
        } else {
          return 0
        }
      }
      const intPart = Math.trunc(value)
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      let floatPart = ''
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) {
        floatPart = valueArray[1].toString()
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    }
  }
}
</script>

<style lang="less">
.ant-table-wrapper {
  font-size: 14px;
  .ant-table-pagination {
    width: 100%;
    text-align: right;
    .ant-pagination-total-text {
      float: left;
    }
  }
}
&::-webkit-scrollbar{
  width: 3px;
  height: 7px;
}
&::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: @sc-greyBg-20;
}
&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0,0,0,0);
  border-radius: 4px;
  background: #fff;
}
.ant-table-fixed-left, .ant-table-fixed-right {
  .ant-table-body-outer {
    margin-bottom: -7px !important;
  }
}
.resize-table-th {
  position: relative;
  .table-draggable-handle {
    height: 100% !important;
    bottom: 0;
    left: auto !important;
    right: -5px;
    cursor: col-resize;
    touch-action: none;
    position: absolute;
    transform: translate(0px, 0px)!important;
  }
}
</style>
