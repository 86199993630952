<template>
  <span :class="`${isClick?'tag':'noneBorder'}`" :style="color" @click="clickTag"> {{ typeList[type].name }} </span>
</template>

<script>
// 审批的几种状态的值
export default {
  name: 'StatusTag',
  props: {
    // 各个标签状态的类型
    type: {
      required: true,
      type: [String, Number],
      default: ''
    },
    // 状态名字的对象列表
    typeList: {
      required: true,
      type: Object,
      default: () => {}
    },
    // 是否可点击
    isClick: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      colorList: {
        color: '',
        bgColor: '',
        bdColor: ''
      },
      statusList: [
        {
          // 审批中
          key: 1,
          status: 'inapprove',
          color: 'rgba(237, 123, 47, 1)',
          bgColor: 'rgba(237, 123, 47, .1)'
        },
        {
          // 已通过
          key: 2,
          status: 'completeapprove',
          color: 'rgba(0, 168, 112, 1)',
          bgColor: 'rgba(0, 168, 112, .1)'
        },
        {
          // 未通过
          key: 3,
          status: 'rejectapprove',
          color: 'rgba(227, 77, 89, 1)',
          bgColor: 'rgba(227, 77, 89, .1)'
        },
        {
          // 等待HR干预
          key: 4,
          status: 'exception',
          color: 'rgba(237, 123, 47, 1)',
          bgColor: 'rgba(237, 123, 47, .1)'
        },
        {
          // 审批终止
          key: 5,
          status: 'exceptionend',
          color: 'rgba(227, 77, 89, 1)',
          bgColor: 'rgba(227, 77, 89, .1)'
        },
        {
          // 撤销审批
          key: 6,
          status: ' revokeapprove',
          color: 'rgba(143, 147, 161, 0.6)',
          bgColor: 'rgba(143, 147, 161, 0.1)'
        }
      ]
    }
  },
  computed: {
    color() {
      if (!this.type) return
      const { color, bgColor, bdColor } = this.colorList
      return `color: ${color}; background: ${bgColor}; ${this.isClick ? `border: 1px solid ${bdColor}` : ''}`
    }
  },
  watch: {
    type: {
      handler: function(val) {
        this.getColor()
      },
      immediate: true
    }
  },
  methods: {
    clickTag() {
      this.$emit('click')
    },
    // 得到标签相对应的颜色功能
    getColor() {
      this.statusList.map(item => {
        if (Number(this.type) === item.key || this.type === item.status) {
          this.colorList.color = item.color
          this.colorList.bgColor = item.bgColor
          this.colorList.bdColor = item.color
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.tag{
  display: inline-block;
  height: 22px;
  padding: 0 11px;
  line-height: 20px;
  border-radius: 11px;
  font-size: 12px;
  &:hover{
    cursor: pointer;
  }
}
.noneBorder{
  display: inline-block;
  height: 22px;
  padding: 0 11px;
  line-height: 20px;
  border-radius: 4px;
  font-size: 12px;
}
</style>

