import VueDraggableResizable from 'vue-draggable-resizable'
import Vue from 'vue'
Vue.component('vue-draggable-resizable', VueDraggableResizable)
export default {
  data() {
    this.components = {
      header: {
        cell: this.ResizeableTitle
      }
    }
  },
  computed: {
    draggingState() {
      const draggingMap = {}
      this.columns.forEach(col => {
        draggingMap[col.dataIndex] = col.width
      })
      return draggingMap
    }
  },
  methods: {
    ResizeableTitle(h, props, children) {
      let thDom = null
      const { key, ...restProps } = props
      const col = this.columns.find(col => {
        const k = col.dataIndex || col.key
        return k === key
      })
      // 没有宽度和作为固定列的不允许拖拽
      if (!col.width || col.fixed) {
        return <th {...restProps}>{children}</th>
      }
      const onDrag = x => {
        this.draggingState[key] = 0
        col.width = Math.max(x, 1)
      }

      const onDragstop = () => {
        this.draggingState[key] = thDom.getBoundingClientRect().width
        this.$emit('watchWidth', this.draggingState[key], key)
      }
      return (
        <th {...restProps} v-ant-ref={r => (thDom = r)} width={col.width} class='resize-table-th'>
          {children}
          <vue-draggable-resizable
            key={col.key}
            class='table-draggable-handle'
            w={10}
            x={this.draggingState[key] || col.width}
            z={999}
            axis='x'
            draggable={true}
            resizable={false}
            onDragging={onDrag}
            onDragstop={onDragstop}
          ></vue-draggable-resizable>
        </th>
      )
    }
  }
}
