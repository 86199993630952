<!--
 * @descriotion: 文化--表格回显接收人员
-->
<template>
  <div>
    <div class="scopeList">
      <div v-for="i in (dataList.length > num ? num : dataList.length)" :key="i" class="personCont personList">
        <CbAvater
          :on-job-id="dataList[i-1].onJobId"
          :avater-name="dataList[i-1].staffName"
          :default-name-length="1"
          size="22"
        />
      </div>
      <a-popover title="适用人员" placement="bottomRight" trigger="hover" :auto-adjust-overflow="false" overlay-class-name="thePopover">
        <template slot="content">
          <div class="scopeList popoverContent">
            <div v-for="item in dataList" :key="item.onJobId" class="personCont">
              <CbAvater
                :on-job-id="item.onJobId"
                :avater-name="item.staffName"
                :default-name-length="1"
                size="22"
              />
            </div>
          </div>
        </template>
        <div v-show="dataList.length > num" class="num">{{ dataList.length - num }}</div>
      </a-popover>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TablePersons',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater')
  },
  props: {
    // 回显的数据
    dataEcho: {
      type: Array,
      default: () => []
    },
    // 一个单元格最多展示几个人员
    num: {
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      dataList: []
    }
  },
  watch: {
    dataEcho: {
      immediate: true,
      handler(val) {
        this.dataList = val
      }
    }
  }
}
</script>
<style lang="less" scoped>
.scopeList{
  width:100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .personList{
    margin: 0 10px 0 0 !important;
  }
  .personCont{
    width:100px;
    margin: 10px 10px 0 0;
    height:26px;
    border-radius: 13px;
    padding: 5px 10px 5px 3px;
    display: flex;
    align-items: center;
    background:  @sc-greyBg-10;
    /deep/ .box .user-name{
      width: calc(100% - 35px);
    }
  }
  .num{
    cursor: pointer;
    color: @sc-primary-100;
    font-weight: 600;
    &:hover{
      color: @sc-primary-60;
    }
  }
}
.popoverContent{
  width:550px;
  .person{
    width:100px;
  }
}
</style>
<style>
.thePopover .ant-popover-inner-content{
  max-height: 350px;
  overflow: auto;
}
</style>
