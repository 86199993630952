// 薪酬-设置-企业账号
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 计薪首页

// 获取所有计薪周期
export async function getSalaryPeriodAll(params) {
  return request(`${WAGE}/calcSalary/getSalaryPeriodAll`, METHOD.GET, params)
}

// 查询计薪周期
export async function getSalaryPeriod(params) {
  return request(`${WAGE}/calcSalary/getSalaryPeriod`, METHOD.GET, params)
}

// 查询薪资表(计薪周期详情)
export async function getSalaryGroupCalc(params) {
  return request(`${WAGE}/calcSalary/getSalaryGroupCalc`, METHOD.GET, params)
}

// 查询无薪资组成员
export async function getNotCalcStaff(params) {
  return request(`${WAGE}/calcSalary/getNotCalcStaff`, METHOD.GET, params)
}

// 保存未计薪人员
export async function saveNotCalcStaff(params) {
  return request(`${WAGE}/calcSalary/saveNotCalcStaff`, METHOD.POST, params)
}

// 开始计薪
export async function startCalc(params) {
  return request(`${WAGE}/calcSalary/startCalc`, METHOD.POST, params)
}

// 取消计薪
export async function cancelCalc(params) {
  return request(`${WAGE}/calcSalary/cancelCalc`, METHOD.POST, params)
}

// 重新计薪
export async function restartCalc(params) {
  return request(`${WAGE}/calcSalary/restartCalc`, METHOD.POST, params)
}

// 获取计薪周期配置
export async function getConfig(params) {
  return request(`${WAGE}/payrollPeroid/getConfig`, METHOD.GET, params)
}

// 设置计薪周期开始时间
export async function setConfig(params) {
  return request(`${WAGE}/payrollPeroid/setConfig`, METHOD.GET, params)
}

// 核对计薪人员
// 核对计薪人员信息
export async function getSalaryCalcStaffInfo(params) {
  return request(`${WAGE}/calcSalary/getSalaryCalcStaffInfo`, METHOD.GET, params)
}

// 核对计薪人员列表
export async function getSalaryStaffPage(params) {
  return request(`${WAGE}/calcSalary/getSalaryStaffPage`, METHOD.GET, params)
}

// 计薪组减少员工
export async function deleteStaffToGroup(params) {
  return request(`${WAGE}/calcSalary/deleteStaffToGroup`, METHOD.POST, params)
}

// 修改入职员工月全额应出勤天数
export async function editYqeycqts(params) {
  return request(`${WAGE}/calcSalary/editYqeycqts`, METHOD.POST, params)
}

// 向计薪组添加员工
export async function addStaffToGroup(params) {
  return request(`${WAGE}/calcSalary/addStaffToGroup`, METHOD.POST, params)
}

// 核对计薪人员列表(全部)
export async function getSalaryStaffAll(params) {
  return request(`${WAGE}/calcSalary/getSalaryStaffAll`, METHOD.GET, params)
}

// 批量更新员工信息
export async function updateStaffToGroup(params) {
  return request(`${WAGE}/calcSalary/updateStaffToGroup`, METHOD.POST, params)
}

// 下一步(前往定薪调薪页面)
export async function forwardSetSalary(params) {
  return request(`${WAGE}/calcSalary/forwardSetSalary`, METHOD.GET, params)
}

// 导出核对算薪人员列表
export async function exportSalaryStaffPage(params) {
  return request(`${WAGE}/calcSalary/exportSalaryStaffPage`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 定薪调薪
// 获取定薪调薪模块基本信息
export async function getStaffFixedInfo(params) {
  return request(`${WAGE}/calcSalary/getStaffFixedInfo`, METHOD.GET, params)
}

// 定薪调薪列表-本月计薪人员列表
export async function getFixedStaff(params) {
  return request(`${WAGE}/calcSalary/getFixedStaff`, METHOD.GET, params)
}

// 定薪
export async function fixedSalary(params) {
  return request(`${WAGE}/calcSalary/fixedSalary`, METHOD.POST, params)
}

// 批量定薪
export async function batchFixedSalary(params, config) {
  return request(`${WAGE}/calcSalary/batchFixedSalary`, METHOD.POST, params, config)
}

// 调薪
export async function changeSalary(params) {
  return request(`${WAGE}/calcSalary/changeSalary`, METHOD.POST, params)
}

// 批量调薪
export async function batchChangeSalary(params, config) {
  return request(`${WAGE}/calcSalary/batchChangeSalary`, METHOD.POST, params, config)
}

// 导出定薪调薪列表-本月算薪人员列表
export async function exportFixedStaff(params) {
  return request(`${WAGE}/calcSalary/exportFixedStaff`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
// 发起审批
export async function salaryApprovalGetSalaryGroupCalc(params) {
  return request(`${WAGE}/salaryApproval/getSalaryGroupCalc`, METHOD.GET, params)
}

// 锁定计薪周期
export async function lockSalaryPeriod(params) {
  return request(`${WAGE}/calcSalary/lockSalaryPeriod`, METHOD.POST, params)
}

// 解锁计薪周期
export async function unLockSalaryPeriod(params) {
  return request(`${WAGE}/calcSalary/unLockSalaryPeriod`, METHOD.POST, params)
}

// 上一步(核对计薪人员)
export async function backwardStaff(params) {
  return request(`${WAGE}/calcSalary/backwardStaff`, METHOD.GET, params)
}

// 上一步(核对计薪人员)
export async function forwardCalc(params) {
  return request(`${WAGE}/calcSalary/forwardCalc`, METHOD.GET, params)
}

// 核计薪资

// 查询核计薪资页面信息
export async function getSalaryCalcInfo(params) {
  return request(`${WAGE}/calcSalary/getSalaryCalcInfo`, METHOD.GET, params)
}

// 查询核酸薪资列表
export async function getCalcSalaryTypePage(params) {
  return request(`${WAGE}/calcSalary/getCalcSalaryTypePage`, METHOD.GET, params)
}

// 查询个人薪资详情（用于修改）
export async function getSalaryStaffByTypeDetail(params) {
  return request(`${WAGE}/calcSalary/getSalaryStaffByTypeDetail`, METHOD.GET, params)
}

// 修改个人薪资数据
export async function updateStaffSalary(params) {
  return request(`${WAGE}/calcSalary/updateStaffSalary`, METHOD.POST, params)
}

// 获取个人明细
export async function getSalaryStaffDetail(params) {
  return request(`${WAGE}/calcSalary/getSalaryStaffDetail`, METHOD.GET, params)
}

// 获取导出明细的模板
export async function salaryIndexItemGetTemplate(params) {
  return request(`${WAGE}/salaryIndexItem/getTemplate`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 导入明细指标项
export async function salaryIndexItemImportIndex(params, config) {
  return request(`${WAGE}/salaryIndexItem/importIndex`, METHOD.POST, params, config)
}

// 导出薪资项模板
export async function exportSalaryCalcBatchTemplate(params) {
  return request(`${WAGE}/calcSalary/exportSalaryCalcBatchTemplate`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 导入薪资合计
export async function batchSetSalaryCalc(params, config) {
  return request(`${WAGE}/calcSalary/batchSetSalaryCalc`, METHOD.POST, params, config)
}

// 导出核算薪资列表
export async function exportCalcSalaryTypePage(params) {
  return request(`${WAGE}/calcSalary/exportCalcSalaryTypePage`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 下一步(前往个税核对)
export async function forwardTax(params) {
  return request(`${WAGE}/calcSalary/forwardTax`, METHOD.GET, params)
}

// 上一步(定薪调薪)
export async function backwardFixed(params) {
  return request(`${WAGE}/calcSalary/backwardFixed`, METHOD.GET, params)
}

// 个税核对
// 导出个税核对信息
export async function exportCurrentMonthCalStaffList(params) {
  return request(`${WAGE}/taxpayCheck/exportCurrentMonthCalStaffList`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 上一步 前往定薪调薪
export async function getCurrentMonthCalStaffList(params) {
  return request(`${WAGE}/taxpayCheck/getCurrentMonthCalStaffList`, METHOD.GET, params)
}

// 上一步 前往定薪调薪
export async function backwardCal(params) {
  return request(`${WAGE}/taxpayCheck/backwardCal`, METHOD.GET, params)
}

// 下一步，前往锁定计薪
export async function forwardFinish(params) {
  return request(`${WAGE}/taxpayCheck/forwardFinish`, METHOD.GET, params)
}

// 计薪完成

// 算薪完成列表
export async function finishGetCalcSalaryTypePage(params) {
  return request(`${WAGE}/salaryCalFinish/getCalcSalaryTypePage`, METHOD.GET, params)
}

// 上一步 前往个税核对
export async function backwardTax(params) {
  return request(`${WAGE}/salaryCalFinish/backwardTax`, METHOD.GET, params)
}

// 查询个人薪资项明细
export async function getSalaryItemDetail(params) {
  return request(`${WAGE}/salaryCalFinish/getSalaryStaffDetail`, METHOD.GET, params)
}

// 导出算薪完成列表
export async function exportSalaryCalFinishList(params) {
  return request(`${WAGE}/salaryCalFinish/exportSalaryCalFinishList`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
